<script>
import Layout from "@/views/layouts/main.vue";
import appConfig from "@/app.config.json";
import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
import axios from "axios";
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";

export default {
  page: {
    title: "수신자 그룹관리",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "수신자 그룹관리",
      items: '수신자 그룹을 관리할 수 있습니다.',
      groupList: [],
      name: '',
      editName: '',
    };
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState(loggedInfoObject),
  },
  mounted() {
    this.getGroupList()
  },
  methods: {
    getGroupList() {
      let url = `/api/web/market/group/vue/${this.logged_info.mart_srl}`

      axios.get(url).then((res) => {
        //console.log(res.data)

        this.groupList = res.data
      }).catch(err => console.log(err))
    },

    newGroup(bvModalEvent) {
      let url = `/api/web/market/group/${this.logged_info.mart_srl}`
      let param = {'name': this.name}

      if (this.name == '') {
        this.$bvModal.msgBoxOk('그룹명을 입력하세요.', msgModalOptCenter)
        bvModalEvent.preventDefault()
      } else {
        axios.post(url, param).then((res) => {
          //console.log(res.data)
          if (res.data.status == 'success') {
            this.$bvModal.msgBoxOk('등록이 완료되었습니다.', msgModalOptCenter)
          }
          this.getGroupList()
        }).catch(err => console.log(err))
      }
    },

    editGroup(name, srl, bvModalEvent) {
      if (name == '') {
        this.$bvModal.msgBoxOk('그룹명을 입력하세요.', msgModalOptCenter)
        bvModalEvent.preventDefault()
      } else {
        this.$bvModal.msgBoxConfirm(`그룹명을 [${name}](으)로 변경하시겠습니까?`, msgConfirmOptCenter)
            .then((sel) => {
              if (sel) {
                let url = `/api/web/market/group/${this.logged_info.mart_srl}`

                let param = {'name': name, 'mk_group_srl': srl}

                axios.put(url, param)
                    .then(() => {
                      //console.log(res.data)
                      this.getGroupList()
                    })
                    .catch(err => console.log(err))
              }
            })
      }
    },

    deleteGroup(val) {
      let str = ''

      if (val.customer_cnt > 0) {
        str = '회원정보가 있는 그룹입니다. 삭제하시겠습니까?'
      } else {
        str = `[${val.gname}] 그룹을 삭제하시겠습니까?`
      }

      this.$bvModal.msgBoxConfirm(str, msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              let url = `/api/web/market/group/${this.logged_info.mart_srl}`
              let param = {'mk_group_srl': val.mk_group_srl}

              axios.delete(url, {data: param})
                  .then((res) => {
                    //console.log(res.data)
                    if (res.data.status == 'success') {
                      this.$bvModal.msgBoxOk('삭제가 완료되었습니다.', msgModalOptCenter)
                    } else {
                      this.$bvModal.msgBoxOk('삭제 실패하였습니다.', msgModalOptCenter)
                    }
                    this.getGroupList()
                  })
                  .catch(err => console.log(err))
            }
          })
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
        <span style="float: left; padding-left: 8px;">
          <h4 class="mb-0 font-size-18">{{ title }} </h4>
          <span :items="items" class="font-size-14" style="color: #777777; text-align: left;">{{ items }}</span>
        </span>
          <span class="page-title-right d-flex align-items-center md-0" style="float: right; padding-right: 8px;">
          <a
              href="javascript: void(0);"
              class="btn btn-primary btn-soft w-100"
              @click="$bvModal.show('modal-addGroup')"
          >
            그룹추가
          </a>
        </span>
        </div>
        <b-modal centered
                 id='modal-addGroup'
                 title="그룹 추가"
                 body-class="p-0 m-0"
                 ok-title="추가"
                 cancel-title="취소"
                 @ok="newGroup"
        >
          <div class="modal-body">
            <div class="row">
              <label class="col-sm-2 col-form-label">
                그룹명
              </label>
              <div class="col-sm-10">
                <input
                    class="form-control"
                    placeholder="그룹명을 입력해주세요."
                    v-model="name"/>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h5>그룹 목록</h5>
        <hr/>
        <div class="table-responsive">
          <table class="table" id="coupon_list">
            <thead class="table-light" style="text-align: center;">
            <tr>
              <th>번호</th>
              <th>그룹명</th>
              <th>관리</th>
            </tr>
            </thead>
            <tbody style="text-align: center;">

            <tr
                v-for="row in groupList"
                :key="row.mk_group_srl"
                style="vertical-align: middle;"
            >
              <td>{{ row.mk_group_srl }}</td>
              <td>{{ row.gname }}</td>
              <td class="tbl-btn-grp" style="text-align: center;">
                <b-button variant="outline-primary"
                          style="margin-right: 5px;"
                          v-b-modal="`editGroup${row.mk_group_srl}`"
                          @click="editName = row.gname">
                  수정
                </b-button>
                <b-modal centered
                         :id='`editGroup${row.mk_group_srl}`'
                         title="그룹명 변경"
                         body-class="p-0 m-0"
                         ok-title="변경"
                         cancel-title="취소"
                         @ok="editGroup(editName, row.mk_group_srl, $event)"
                >
                  <div class="modal-body">
                    <div class="row">
                      <label class="col-sm-2 col-form-label">
                        그룹명
                      </label>
                      <div class="col-sm-10">
                        <input class="form-control" placeholder="그룹명을 입력해주세요." v-model="editName"/>
                      </div>
                    </div>
                  </div>
                </b-modal>
                <b-button
                    variant="outline-danger"
                    @click="deleteGroup(row)"
                >
                  삭제
                </b-button>
              </td>
            </tr>

            </tbody>
          </table>
        </div>

      </div>
    </div>
  </Layout>
</template>
